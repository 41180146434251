import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ButtonLoader from "../components/ButtonLoader";
import DataLoader from "../components/DataLoader";
import DeleteModal from "../components/DeleteModal";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";

const ProductsCategories = () => {
  const gtx = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [refetch, setRefetch] = useState(true);
  const [responseError, setResponseError] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      image: "",
    },
  });
  const submitHandler = (data) => {
    setButtonDisabled(true);
    let params = {};
    let url = `${process.env.REACT_APP_BACKEND_URL}category`;
    if (isEdit) {
      params = {
        id: selectedItem.id,
        name: data.name,
        name_fr: data.name_fr,
        name_es: data.name_es,
        product_category_image: data.image[0],
        _method: "PUT",
      };
      url += `/${selectedItem.id}`;
    } else {
      params = {
        name: data.name,
        name_fr: data.name_fr,
        name_es: data.name_es,
        product_category_image: data.image[0],
      };
    }
    axios({
      method: "post",
      url: url,
      data: params,
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function () {
        setRefetch(!refetch);
        setButtonDisabled(false);
        reset();
        setIsEdit(false);
        setSelectedItem(null);
        setResponseError("");
      })
      .catch(function (err) {
        setButtonDisabled(false);
        setResponseError("Something went wrong!");
        throw err;
      });
  };

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}category`)
      .then((res) => {
        setData(res.data.data);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [refetch]);

  const modalHandler = (link) => {
    setShowModal(true);
    setItemId(link);
  };

  const deleteHandler = () => {
    setShowModal(false);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}category/${itemId}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setRefetch(!refetch);
      })
      .catch(function (err) {
        alert("Something went wrong!");
        throw err;
      });
  };

  const editItem = (item) => {
    setValue("name", item.en.name);
    setValue("name_fr", item.fr.name);
    setValue("name_es", item.es.name);
    setValue("image", item.image);
    setSelectedItem(item);
    setIsEdit(true);
    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    reset();
    setIsEdit(false);
    setSelectedItem(null);
    setResponseError("");
  };

  useEffect(() => {
    setImagePreview(typeof watch("image") !== "string" && watch("image")[0]);
    // eslint-disable-next-line
  }, [watch("image")]);

  return (
    <Layout active="Products Categories">
      <form className="pt-5" onSubmit={handleSubmit(submitHandler)}>
        <div className="form-wrapper">
          <p className="form-wrapper-title">Products Categories</p>
          <label htmlFor="name">Category name (en)</label>
          <input
            id="name"
            name="name"
            type="text"
            {...register("name", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="info">Category image</label>
          <input
            id="image"
            name="image"
            type="file"
            {...register("image", {
              required: {
                value: isEdit ? false : true,
                message: "empty",
              },
            })}
          />
          <h5 className="fr">Translatable fields (FR):</h5>
          <label htmlFor="name_fr">Category name</label>
          <input
            id="name_fr"
            name="name_fr"
            type="text"
            {...register("name_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <h5 className="es">Translatable fields (ES):</h5>
          <label htmlFor="name_es">Category name</label>
          <input
            id="name_es"
            name="name_es"
            type="text"
            {...register("name_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          {selectedItem && !imagePreview && (
            <div className="ratio ratio-1x1 w-200 mt-2">
              <img
                src={process.env.REACT_APP_IMAGES_URL + selectedItem.image}
                alt="selected"
                className="cover"
              />
            </div>
          )}
          {imagePreview && (
            <div className="ratio ratio-1x1 w-200 mt-2">
              <img
                src={URL.createObjectURL(imagePreview)}
                alt="selected"
                className="cover"
              />
            </div>
          )}
          {(errors.name?.message === "empty" ||
            errors.name_fr?.message === "empty" ||
            errors.name_es?.message === "empty" ||
            errors.image?.message === "empty") && (
            <p className="error-message">All fields are required</p>
          )}
          {responseError !== "" && (
            <p className="error-message">Something went wrong!</p>
          )}
          <div className="row d-flex">
            <button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? (
                <ButtonLoader />
              ) : (
                `${isEdit ? "Edit" : "Add"} Item`
              )}
            </button>
            <button
              type="reset"
              className="delete-button-general"
              onClick={resetForm}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      <div className="row pt-2 pb-5 px-2">
        {loadingData ? (
          <div className="col-12 d-flex justify-content-center pt-5">
            <DataLoader />
          </div>
        ) : (
          data.map((link, index) => (
            <div key={index} className="col-12 py-4 data-container mt-4">
              <table>
                <tbody>
                  <tr>
                    <th>category name (en): </th>
                    <td>{link.en.name}</td>
                  </tr>
                  <tr>
                    <th>category name (fr): </th>
                    <td>{link.fr.name}</td>
                  </tr>
                  <tr>
                    <th>category name (es): </th>
                    <td>{link.es.name}</td>
                  </tr>
                  <tr>
                    <th>Category image: </th>
                    <td>
                      <div className="ratio ratio-1x1 w-200 mt-1">
                        <img
                          src={process.env.REACT_APP_IMAGES_URL + link.image}
                          alt="category"
                          className="cover"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="data-buttons">
                {link.products.length === 0 && (
                  <button
                    className="delete-button-general me-3"
                    onClick={() => modalHandler(link.id)}
                  >
                    Delete
                  </button>
                )}
                <Link
                  className="link-no-styles"
                  to={`/products-categories/${link.slug}`}
                >
                  <button className="edit-button-general me-3">
                    Manage products
                  </button>
                </Link>
                <button
                  onClick={() => {
                    editItem(link);
                  }}
                  className="edit-button-general"
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <DeleteModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        onDelete={deleteHandler}
      />
    </Layout>
  );
};

export default ProductsCategories;
