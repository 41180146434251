import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataLoader from "../components/DataLoader";
import Layout from "../components/Layout";
import ReactPaginate from "react-paginate";

const ContactMessages = () => {
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}ContactUs?${search}`)
      .then((res) => {
        setData(res.data.data);
        setMetaData(res.data.meta);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [search]);

  return (
    <Layout active="Contact Messages">
      <div className="row pt-2 pb-5 px-2">
        {loadingData ? (
          <div className="col-12 d-flex justify-content-center pt-5">
            <DataLoader />
          </div>
        ) : (
          data.map((link, index) => (
            <div key={index} className="col-12 py-4 data-container mt-4">
              <table>
                <tbody>
                  <tr>
                    <th>First Name: </th>
                    <td>{link.first_name}</td>
                  </tr>
                  <tr>
                    <th>Last Name: </th>
                    <td>{link.last_name}</td>
                  </tr>
                  <tr>
                    <th>Email: </th>
                    <td>
                      <a href={`mailto:${link.email}`}>{link.email}</a>
                    </td>
                  </tr>
                  <tr>
                    <th>Message: </th>
                    <td>{link.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        )}
        {metaData.last_page > 1 && !loadingData && (
          <div className="col-12 pt-4">
            <div className="d-flex justify-content-center">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                previousLinkClassName={"pagination-previous"}
                containerClassName={"pagination-container"}
                nextLinkClassName={"pagination-next"}
                activeClassName={"pagination-active"}
                disabledClassName={"pagination-disabled"}
                onPageChange={(page) => setSearch({ page: page.selected + 1 })}
                pageCount={metaData.last_page}
                forcePage={metaData.current_page - 1}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContactMessages;
