import React from "react";

const DeleteModal = (props) => {
  return (
    <div
      className={`position-fixed top-0 start-0 vh-100 w-100 delete-modal-container ${
        props.showModal === true ? "show" : ""
      }`}
      onClick={props.hideModal}
    >
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center vh-100">
          <div
            onClick={(e) => e.stopPropagation()}
            className="col-5 delete-modal-small-container p-4 d-flex flex-column"
          >
            <h3>Are you sure you want to delete this item?</h3>
            <div className="align-self-center">
              <button
                onClick={props.onDelete}
                className="delete-button-general mt-3 align-self-center"
              >
                Delete
              </button>
              <button
                onClick={props.hideModal}
                className="cancel-button-general ms-3 mt-3 align-self-center"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
