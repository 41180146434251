// General imports
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import GlobalContext from "./GlobalContext";

// Components imports
import ScrollToTop from "./components/ScrollToTop";
import LogIn from "./pages/LogIn";
import { useEffect, useState } from "react";
import HomeCarousel from "./pages/HomeCarousel";
// import HomeNews from "./pages/HomeNews";
import CompanyProfile from "./pages/CompanyProfile";
import Location from "./pages/Location";
import ProductsCategories from "./pages/ProductsCategories";
import Products from "./pages/Products";
import ContactMessages from "./pages/ContactMessages";
import { useCookies } from "react-cookie";
import axios from "axios";

function App() {
  const [loggedIn, setLoggedIn] = useState();
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(["access-token"]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_AUTHENTICATION_URL}profile`, {
        headers: {
          Authorization: `Bearer ${cookies["access-token"]}`,
        },
      })
      .then((r) => {
        setLoggedIn(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoggedIn(false);
        navigate("/login");
        // throw err;
      });
  }, [cookies, navigate]);

  return (
    <GlobalContext.Provider
      value={{ loggedIn, setLoggedIn, loading, setLoading, cookies }}
    >
      <ScrollToTop>
        <Routes>
          {loggedIn && (
            <>
              <Route index element={<HomeCarousel />} />
              {/* <Route path="/home-news" element={<HomeNews />} /> */}
              <Route path="/company-profile" element={<CompanyProfile />} />
              <Route path="/location" element={<Location />} />
              <Route
                exact
                path="/products-categories"
                element={<ProductsCategories />}
              />
              <Route path="/products-categories/:slug" element={<Products />} />
              <Route path="/contact-messages" element={<ContactMessages />} />
            </>
          )}
          <Route exact path="/" element={<Navigate to="/login" />} />
          <Route path="login" element={<LogIn />} />
        </Routes>
      </ScrollToTop>

      {/* Loader */}
      {loading && (
        <div className="position-fixed top-0 start-0 vw-100 vh-100 lightgray-bg front">
          <div className="position-relative h-100 w-100 d-flex justify-content-center align-items-center">
            <div id="wrapper">
              <div
                className="gears d-flex justify-content-center"
                id="two-gears"
              >
                <div className="gears-container">
                  <div className="gear-rotate"></div>
                  <div className="gear-rotate-left"></div>
                </div>
              </div>
            </div>
            <div className="gears-try">
              <i></i>
              <i></i>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Provider>
  );
}

export default App;
