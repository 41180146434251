import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoader from "../components/ButtonLoader";
import DataLoader from "../components/DataLoader";
import DeleteModal from "../components/DeleteModal";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";

const CompanyProfile = () => {
  const gtx = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const [requestErrors, setRequestErrors] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      info: "",
    },
  });
  const submitHandler = (data) => {
    setButtonDisabled(true);
    let params = {};
    let url = `${process.env.REACT_APP_BACKEND_URL}company_info`;
    if (isEdit) {
      params = {
        id: selectedItem.id,
        title: data.title,
        title_fr: data.title_fr,
        title_es: data.title_es,
        info: data.info,
        info_fr: data.info_fr,
        info_es: data.info_es,
        order: data.order,
        _method: "PUT",
      };
      url += `/${selectedItem.id}`;
    } else {
      params = {
        title: data.title,
        title_fr: data.title_fr,
        title_es: data.title_es,
        info: data.info,
        info_fr: data.info_fr,
        info_es: data.info_es,
        order: data.order,
      };
    }
    axios({
      method: "post",
      url: url,
      data: params,
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function () {
        setRefetch(!refetch);
        setButtonDisabled(false);
        setIsEdit(false);
        setSelectedItem(null);
        setRequestErrors("");
        reset();
      })
      .catch(function (err) {
        setButtonDisabled(false);
        setRequestErrors("Something went wrong!");
        throw err;
      });
  };

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}company_info`)
      .then((res) => {
        setData(res.data.data);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [refetch]);

  const modalHandler = (link) => {
    setShowModal(true);
    setItemId(link);
  };

  const deleteHandler = () => {
    setShowModal(false);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}company_info/${itemId}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setRefetch(!refetch);
      })
      .catch(function (err) {
        alert("Something went wrong!");
        throw err;
      });
  };

  const editItem = (item) => {
    setValue("title", item.en.title);
    setValue("title_fr", item.fr.title);
    setValue("title_es", item.es.title);
    setValue("order", item.order);
    setValue("info", item.en.info);
    setValue("info_fr", item.fr.info);
    setValue("info_es", item.es.info);
    setSelectedItem(item);
    setIsEdit(true);
    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    reset();
    setIsEdit(false);
    setSelectedItem(null);
  };

  return (
    <Layout active="Company Profile">
      <form className="pt-5" onSubmit={handleSubmit(submitHandler)}>
        <div className="form-wrapper">
          <p className="form-wrapper-title">Company Profile</p>
          <label htmlFor="title">title</label>
          <input
            id="title"
            name="title"
            type="text"
            {...register("title", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="order">order</label>
          <input
            id="order"
            name="order"
            type="number"
            {...register("order", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="info">info</label>
          <div className="grow-wrap">
            <textarea
              id="info"
              name="info"
              type="text"
              {...register("info", {
                required: {
                  value: true,
                  message: "empty",
                },
              })}
            />
          </div>
          <h5 className="fr">Translatable fields (FR):</h5>
          <label htmlFor="title_fr">title</label>
          <input
            id="title_fr"
            name="title_fr"
            type="text"
            {...register("title_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="info_fr">info</label>
          <div className="grow-wrap">
            <textarea
              id="info_fr"
              name="info_fr"
              type="text"
              {...register("info_fr", {
                required: {
                  value: true,
                  message: "empty",
                },
              })}
            />
          </div>
          <h5 className="es">Translatable fields (ES):</h5>
          <label htmlFor="title_es">title</label>
          <input
            id="title_es"
            name="title_es"
            type="text"
            {...register("title_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="info_es">info</label>
          <div className="grow-wrap">
            <textarea
              id="info_es"
              name="info_es"
              type="text"
              {...register("info_es", {
                required: {
                  value: true,
                  message: "empty",
                },
              })}
            />
          </div>
          {(errors.title?.message === "empty" ||
            errors.title_fr?.message === "empty" ||
            errors.title_es?.message === "empty" ||
            errors.info?.message === "empty" ||
            errors.info_fr?.message === "empty" ||
            errors.info_es?.message === "empty" ||
            errors.order?.message === "empty") && (
            <p className="error-message">All fields are required</p>
          )}
          {requestErrors !== "" && (
            <p className="error-message">Something went wrong!</p>
          )}
          <div className="row d-flex">
            <button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? (
                <ButtonLoader />
              ) : (
                `${isEdit ? "Edit" : "Add"} Item`
              )}
            </button>
            <button
              type="reset"
              className="delete-button-general"
              onClick={resetForm}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      <div className="row pt-2 pb-5 px-2">
        {loadingData ? (
          <div className="col-12 d-flex justify-content-center pt-5">
            <DataLoader />
          </div>
        ) : (
          data.map((link, index) => (
            <div key={index} className="col-12 py-4 data-container mt-4">
              <table>
                <tbody>
                  <tr>
                    <th>Title (en): </th>
                    <td>{link.en.title}</td>
                  </tr>
                  <tr>
                    <th>Title (fr): </th>
                    <td>{link.fr.title}</td>
                  </tr>
                  <tr>
                    <th>Title (es): </th>
                    <td>{link.es.title}</td>
                  </tr>
                  {link.order && (
                    <tr>
                      <th>Order: </th>
                      <td>{link.order}</td>
                    </tr>
                  )}
                  <tr>
                    <th>info (en): </th>
                    <td>{link.en.info}</td>
                  </tr>
                  <tr>
                    <th>info (fr): </th>
                    <td>{link.fr.info}</td>
                  </tr>
                  <tr>
                    <th>info (es): </th>
                    <td>{link.es.info}</td>
                  </tr>
                </tbody>
              </table>
              <div className="data-buttons">
                <button
                  className="delete-button-general me-3"
                  onClick={() => modalHandler(link.id)}
                >
                  Delete
                </button>
                <button
                  className="edit-button-general"
                  onClick={() => {
                    editItem(link);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <DeleteModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        onDelete={deleteHandler}
      />
    </Layout>
  );
};

export default CompanyProfile;
