import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import GlobalContext from "../GlobalContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

const LogIn = () => {
  const gtx = useContext(GlobalContext);
  // eslint-disable-next-line
  const [cookie, setCookie] = useCookies(["access-token"]);
  const [responseError, setResponseError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (gtx.loggedIn === true) {
      navigate("/");
    }
  }, [gtx.loggedIn, navigate]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mail: "",
      password: "",
    },
  });

  const onSubmit = (data) => {
    if (errors !== {}) {
      gtx.setLoading(true);
      axios
        .post(`${process.env.REACT_APP_AUTHENTICATION_URL}login`, {
          email: data.mail,
          password: data.password,
        })
        .then((res) => {
          if (res.data.access_toekn) {
            reset();
            gtx.setLoggedIn(true);
            gtx.setLoading(false);
            navigate("/");
            setCookie("access-token", res.data.access_toekn, { path: "/" });
          } else if (res.data.message) {
            setResponseError(true);
          }
          gtx.setLoading(false);
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="container-fluid position-relative vh-100 w-100">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-lg-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-wrapper">
              <p className="form-wrapper-title">Log In</p>
              <label htmlFor="mail">Email</label>
              <input
                id="mail"
                type="email"
                name="mail"
                {...register("mail", {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "emailNotValid",
                  },
                  required: {
                    value: true,
                    message: "empty",
                  },
                })}
              />
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                name="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "empty",
                  },
                })}
              />
              {(errors.mail?.message === "empty" ||
                errors.password?.message === "empty") && (
                <p className="error-message">Email and Password are required</p>
              )}
              {errors.mail?.message === "emailNotValid" &&
                errors.password?.message !== "empty" && (
                  <p className="error-message">Enter a valid email address</p>
                )}
              {errors.mail?.message !== "emailNotValid" &&
                errors.password?.message !== "empty" &&
                responseError === true && (
                  <p className="error-message">
                    Email or password is incorrect
                  </p>
                )}
              <button type="submit">Log In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
