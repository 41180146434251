import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useParams, useSearchParams } from "react-router-dom";
import DataLoader from "../components/DataLoader";
import DeleteModal from "../components/DeleteModal";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";
import ImageUploading from "react-images-uploading";
import { MdDelete } from "react-icons/md";
import ButtonLoader from "../components/ButtonLoader";

const Products = () => {
  const slug = useParams();
  const gtx = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [refetch, setRefetch] = useState(true);
  const [search, setSearch] = useSearchParams();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [images, setImages] = useState([]);
  const [responseError, setResponseError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      serial_number: "",
    },
  });

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "category/" +
          slug.slug +
          "/products?" +
          search
      )
      .then((res) => {
        setData(res.data.data);
        setPagesNumber(res.data.data.last_page);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [refetch, search, slug.slug]);

  const submitHandler = (product) => {
    setButtonDisabled(true);
    let url = `${process.env.REACT_APP_BACKEND_URL}product`;
    let formData = new FormData();
    formData.append("name", product.name);
    formData.append("name_fr", product.name_fr);
    formData.append("name_es", product.name_es);
    formData.append("description", product.description);
    formData.append("description_fr", product.description_fr);
    formData.append("description_es", product.description_es);
    for (const image of images) {
      formData.append("product_images[]", image.file);
    }
    formData.append("category", data.id);
    formData.append("serial_number", product.serial_number);
    if (isEdit) {
      formData.append("_method", "PUT");
      formData.append("id", selectedItem.id);
      url += `/${selectedItem.id}`;
    }
    axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        setRefetch(!refetch);
        setButtonDisabled(false);
        setImages([]);
        setResponseError("");
        resetForm();
      })
      .catch(function (err) {
        setResponseError(
          "Something went wrong! (Make sure to not exceed 4 images per product. Images must be of type jpeg, png, jpg, gif, svg)"
        );
        setButtonDisabled(false);
        throw err;
      });
  };

  const modalHandler = (link) => {
    setShowModal(true);
    setItemId(link);
  };

  const deleteHandler = () => {
    setShowModal(false);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}product/${itemId}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setRefetch(!refetch);
      })
      .catch(function (err) {
        alert("Something went wrong!");
        throw err;
      });
  };

  const imagesChangeHandler = (imageList, addUpdateIndex, e) => {
    // data for submit
    setImages(imageList);
  };

  const editItem = (item) => {
    setValue("name", item.en.name);
    setValue("name_fr", item.fr.name);
    setValue("name_es", item.es.name);
    setValue("description", item.en.description);
    setValue("description_fr", item.fr.description);
    setValue("description_es", item.es.description);
    setValue("category", item.category);
    setValue("serial_number", item.serial_number);
    setSelectedItem(item);
    setIsEdit(true);
    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    reset();
    setImages([]);
    setResponseError("");
    setIsEdit(false);
    setSelectedItem(null);
    setButtonDisabled(false);
  };

  const imageDeleteHandler = (id, i) => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}product_image/${id}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        let selI = Object.assign({}, selectedItem);
        selI.images.splice(i, 1);
        setSelectedItem(selI);
        setRefetch(!refetch);
      })
      .catch(function (err) {
        alert("Something went wrong!");
        throw err;
      });
  };

  return (
    <Layout active="Products">
      <form className="pt-5" onSubmit={handleSubmit(submitHandler)}>
        <div className="form-wrapper">
          <p className="form-wrapper-title">Products</p>
          <label htmlFor="name">Product name</label>
          <input
            id="name"
            name="name"
            type="text"
            {...register("name", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="description">Product Description</label>
          <input
            id="description"
            name="description"
            type="text"
            {...register("description", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="images">Category images (maximum of 4 images)</label>
          <ImageUploading
            id="images"
            multiple
            value={images}
            withPreview={true}
            buttonText="Choose images"
            onChange={imagesChangeHandler}
            maxNumber={4}
            dataURLKey="data_url"
            className="images-upload-container"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  className="my-2"
                  {...dragProps}
                  type="button"
                >
                  Click or Drop here
                </button>
                &nbsp;
                <button
                  onClick={onImageRemoveAll}
                  className="my-2"
                  type="button"
                >
                  Remove all images
                </button>
                {imageList.map((image, index) => (
                  <div key={index} className="image-item py-3">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        onClick={() => onImageUpdate(index)}
                        className="me-2"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
          <label htmlFor="serial_number">Product serial number</label>
          <input
            id="serial_number"
            name="serial_number"
            type="text"
            {...register("serial_number", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />

          <h5 className="fr">Translatable fields (FR):</h5>
          <label htmlFor="name_fr">Product name</label>
          <input
            id="name_fr"
            name="name_fr"
            type="text"
            {...register("name_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="description_fr">Product Description</label>
          <input
            id="description_fr"
            name="description_fr"
            type="text"
            {...register("description_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />

          <h5 className="es">Translatable fields (ES):</h5>
          <label htmlFor="name_es">Product name</label>
          <input
            id="name_es"
            name="name_es"
            type="text"
            {...register("name_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="description_es">Product Description</label>
          <input
            id="description_es"
            name="description_es"
            type="text"
            {...register("description_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <div className="row">
            {selectedItem &&
              selectedItem.images.map((link, index) => (
                <div key={index} className="col-3 mt-2">
                  <div className="ratio ratio-1x1">
                    <img
                      src={process.env.REACT_APP_IMAGES_URL + link.image}
                      alt="product"
                      className="cover"
                    />
                    <button
                      onClick={() => imageDeleteHandler(link.id, index)}
                      className="delete-button"
                      type="button"
                    >
                      <div className="ratio ratio-1x1">
                        <MdDelete style={{ color: "red" }} />
                      </div>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {(errors.name?.message === "empty" ||
            errors.name_fr?.message === "empty" ||
            errors.name_es?.message === "empty" ||
            errors.description?.message === "empty" ||
            errors.description_fr?.message === "empty" ||
            errors.description_es?.message === "empty" ||
            errors.serial_number?.message === "empty") && (
            <p className="error-message">All fields are required</p>
          )}
          {responseError !== "" && (
            <p className="error-message">{responseError}</p>
          )}
          <div className="row d-flex">
            <button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? (
                <ButtonLoader />
              ) : (
                `${isEdit ? "Edit" : "Add"} Item`
              )}
            </button>
            <button
              type="reset"
              className="delete-button-general"
              onClick={resetForm}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      <div className="row pt-2 pb-5 px-2">
        {loadingData ? (
          <div className="col-12 d-flex justify-content-center pt-5">
            <DataLoader />
          </div>
        ) : (
          data.products.map((link, index) => (
            <div key={index} className="col-12 py-4 data-container mt-4">
              <table>
                <tbody>
                  <tr>
                    <th>product name (en): </th>
                    <td>{link.en.name}</td>
                  </tr>
                  <tr>
                    <th>product name (fr): </th>
                    <td>{link.fr.name}</td>
                  </tr>
                  <tr>
                    <th>product name (es): </th>
                    <td>{link.es.name}</td>
                  </tr>
                  <tr>
                    <th>product description (en): </th>
                    <td>{link.en.description}</td>
                  </tr>
                  <tr>
                    <th>product description (fr): </th>
                    <td>{link.fr.description}</td>
                  </tr>
                  <tr>
                    <th>product description (es): </th>
                    <td>{link.es.description}</td>
                  </tr>
                  {/* <tr>
                    <th>Product image: </th>
                    <td>
                      <div className="ratio ratio-1x1 w-200 mt-2">
                        <img
                          src={
                            process.env.REACT_APP_IMAGES_URL +
                            link.images[0]?.image
                          }
                          alt="product"
                          className="cover"
                        />
                      </div>
                    </td>
                  </tr> */}
                  <tr>
                    <th>product serial number: </th>
                    <td>{link.serial_number}</td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex flex-column ps-3 ms-1 products-images">
                <h3>Product Images:</h3>
                <div className="row">
                  {link.images.length !== 0 ? (
                    link.images.map((image, key) => (
                      <div key={key} className="col-3">
                        <div className="ratio ratio-1x1">
                          <img
                            src={process.env.REACT_APP_IMAGES_URL + image.image}
                            alt="product"
                            className="cover"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="success-message">
                      There are no images for this product.
                    </p>
                  )}
                </div>
              </div>
              <div className="data-buttons">
                <button
                  className="delete-button-general me-3"
                  onClick={() => modalHandler(link.id)}
                >
                  Delete
                </button>
                <button
                  className="edit-button-general"
                  onClick={() => {
                    editItem(link);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        )}
        {pagesNumber > 1 && (
          <div className="col-12 pt-5">
            <div className="d-flex justify-content-center">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                previousLinkClassName={"pagination-previous"}
                containerClassName={"pagination-container"}
                nextLinkClassName={"pagination-next"}
                activeClassName={"pagination-active"}
                disabledClassName={"pagination-disabled"}
                onPageChange={(page) => setSearch({ page: page.selected + 1 })}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pagesNumber}
                forcePage={data.current_page - 1}
              />
            </div>
          </div>
        )}
      </div>
      <DeleteModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        onDelete={deleteHandler}
      />
    </Layout>
  );
};

export default Products;
