import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoader from "../components/ButtonLoader";
import DataLoader from "../components/DataLoader";
import DeleteModal from "../components/DeleteModal";
import Layout from "../components/Layout";
import GlobalContext from "../GlobalContext";

const Location = () => {
  const gtx = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [refetch, setRefetch] = useState(true);
  const [requestErrors, setRequestErrors] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      info: "",
    },
  });
  const submitHandler = (data) => {
    setButtonDisabled(true);
    let params = {};
    let url = `${process.env.REACT_APP_BACKEND_URL}location`;
    if (isEdit) {
      params = {
        id: selectedItem.id,
        name: data.name,
        name_fr: data.name_fr,
        name_es: data.name_es,
        longitude: data.longitude,
        latitude: data.latitude,
        street: data.street,
        street_fr: data.street_fr,
        street_es: data.street_es,
        city: data.city,
        city_fr: data.city_fr,
        city_es: data.city_es,
        country: data.country,
        country_fr: data.country_fr,
        country_es: data.country_es,
        _method: "PUT",
      };
      url += `/${selectedItem.id}`;
    } else {
      params = {
        name: data.name,
        name_fr: data.name_fr,
        name_es: data.name_es,
        longitude: data.longitude,
        latitude: data.latitude,
        street: data.street,
        street_fr: data.street_fr,
        street_es: data.street_es,
        city: data.city,
        city_fr: data.city_fr,
        city_es: data.city_es,
        country: data.country,
        country_fr: data.country_fr,
        country_es: data.country_es,
      };
    }
    axios({
      method: "post",
      url: url,
      data: params,
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setRefetch(!refetch);
        setButtonDisabled(false);
        setIsEdit(false);
        setSelectedItem(null);
        setRequestErrors("");
        reset();
      })
      .catch(function (err) {
        setButtonDisabled(false);
        setRequestErrors("Something went wrong!");
        throw err;
      });
  };

  useEffect(() => {
    setLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}location`)
      .then((res) => {
        setData(res.data.data);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [refetch]);

  const modalHandler = (link) => {
    setShowModal(true);
    setItemId(link);
  };

  const deleteHandler = () => {
    setShowModal(false);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}location/${itemId}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setRefetch(!refetch);
      })
      .catch(function (err) {
        alert("Something went wrong!");
        throw err;
      });
  };

  const editItem = (item) => {
    setValue("name", item.en.name);
    setValue("name_fr", item.fr.name);
    setValue("name_es", item.es.name);
    setValue("longitude", item.longitude);
    setValue("latitude", item.latitude);
    setValue("street", item.en.street);
    setValue("street_fr", item.fr.street);
    setValue("street_es", item.es.street);
    setValue("city", item.en.city);
    setValue("city_fr", item.fr.city);
    setValue("city_es", item.es.city);
    setValue("country", item.en.country);
    setValue("country_fr", item.fr.country);
    setValue("country_es", item.es.country);
    setSelectedItem(item);
    setIsEdit(true);
    window.scrollTo(0, 0);
  };

  const resetForm = () => {
    reset();
    setIsEdit(false);
    setSelectedItem(null);
  };

  return (
    <Layout active="Location">
      <form className="pt-5" onSubmit={handleSubmit(submitHandler)}>
        <div className="form-wrapper">
          <p className="form-wrapper-title">Locations</p>
          <label htmlFor="name">name</label>
          <input
            id="name"
            name="name"
            type="text"
            {...register("name", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="longitude">longitude</label>
          <input
            id="longitude"
            name="longitude"
            type="number"
            step="any"
            {...register("longitude", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="latitude">latitude</label>
          <input
            id="latitude"
            name="latitude"
            type="number"
            step="any"
            {...register("latitude", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="street">street</label>
          <input
            id="street"
            name="street"
            type="text"
            {...register("street", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="city">city</label>
          <input
            id="city"
            name="city"
            type="text"
            {...register("city", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="country">country</label>
          <input
            id="country"
            name="country"
            type="text"
            {...register("country", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <h5 className="fr">Translatable fields (FR):</h5>
          <label htmlFor="name_fr">name</label>
          <input
            id="name_fr"
            name="name_fr"
            type="text"
            {...register("name_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="street_fr">street</label>
          <input
            id="street_fr"
            name="street_fr"
            type="text"
            {...register("street_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="city_fr">city</label>
          <input
            id="city_fr"
            name="city_fr"
            type="text"
            {...register("city_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="country_fr">country</label>
          <input
            id="country_fr"
            name="country_fr"
            type="text"
            {...register("country_fr", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <h5 className="es">Translatable fields (ES):</h5>
          <label htmlFor="name_es">name</label>
          <input
            id="name_es"
            name="name_es"
            type="text"
            {...register("name_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="street_es">street</label>
          <input
            id="street_es"
            name="street_es"
            type="text"
            {...register("street_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="city_es">city</label>
          <input
            id="city_es"
            name="city_es"
            type="text"
            {...register("city_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          <label htmlFor="country_es">country</label>
          <input
            id="country_es"
            name="country_es"
            type="text"
            {...register("country_es", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          {(errors.name?.message === "empty" ||
            errors.name_fr?.message === "empty" ||
            errors.name_es?.message === "empty" ||
            errors.longitude?.message === "empty" ||
            errors.latitude?.message === "empty" ||
            errors.street?.message === "empty" ||
            errors.street_fr?.message === "empty" ||
            errors.street_es?.message === "empty" ||
            errors.city?.message === "empty" ||
            errors.city_fr?.message === "empty" ||
            errors.city_es?.message === "empty" ||
            errors.country?.message === "empty" ||
            errors.country_fr?.message === "empty" ||
            errors.country_es?.message === "empty") && (
            <p className="error-message">All fields are required</p>
          )}
          {requestErrors !== "" && (
            <p className="error-message">Something went wrong!</p>
          )}
          <div className="row d-flex">
            <button type="submit" disabled={buttonDisabled}>
              {buttonDisabled ? (
                <ButtonLoader />
              ) : (
                `${isEdit ? "Edit" : "Add"} Item`
              )}
            </button>
            <button
              type="reset"
              className="delete-button-general"
              onClick={resetForm}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      <div className="row pt-2 pb-5 px-2">
        {loadingData ? (
          <div className="col-12 d-flex justify-content-center pt-5">
            <DataLoader />
          </div>
        ) : (
          data.map((link, index) => (
            <div key={index} className="col-12 py-4 data-container mt-4">
              <table>
                <tbody>
                  <tr>
                    <th>name (en):</th>
                    <td>{link.en.name}</td>
                  </tr>
                  <tr>
                    <th>name (fr):</th>
                    <td>{link.fr.name}</td>
                  </tr>
                  <tr>
                    <th>name (es):</th>
                    <td>{link.es.name}</td>
                  </tr>
                  <tr>
                    <th>longitude: </th>
                    <td>{link.longitude}</td>
                  </tr>
                  <tr>
                    <th>latitude: </th>
                    <td>{link.latitude}</td>
                  </tr>
                  <tr>
                    <th>street (en): </th>
                    <td>{link.en.street}</td>
                  </tr>
                  <tr>
                    <th>street (fr): </th>
                    <td>{link.fr.street}</td>
                  </tr>
                  <tr>
                    <th>street (es): </th>
                    <td>{link.es.street}</td>
                  </tr>
                  <tr>
                    <th>city (en): </th>
                    <td>{link.en.city}</td>
                  </tr>
                  <tr>
                    <th>city (fr): </th>
                    <td>{link.fr.city}</td>
                  </tr>
                  <tr>
                    <th>city (es): </th>
                    <td>{link.es.city}</td>
                  </tr>
                  <tr>
                    <th>country (en): </th>
                    <td>{link.en.country}</td>
                  </tr>
                  <tr>
                    <th>country (fr): </th>
                    <td>{link.fr.country}</td>
                  </tr>
                  <tr>
                    <th>country (es): </th>
                    <td>{link.es.country}</td>
                  </tr>
                </tbody>
              </table>
              <div className="data-buttons">
                <button
                  className="delete-button-general me-3"
                  onClick={() => modalHandler(link.id)}
                >
                  Delete
                </button>
                <button
                  className="edit-button-general"
                  onClick={() => {
                    editItem(link);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      <DeleteModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        onDelete={deleteHandler}
      />
    </Layout>
  );
};

export default Location;
