import axios from "axios";
import React, { Fragment, useContext } from "react";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import GlobalContext from "../GlobalContext";

const Layout = (props) => {
  const gtx = useContext(GlobalContext);
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies(["access-token"]);
  const menuItems = [
    { name: "Home Carousel", path: "/" },
    // { name: "Home News", path: "/home-news" },
    { name: "Company Profile", path: "/company-profile" },
    { name: "Locations", path: "/location" },
    { name: "Products Categories", path: "/products-categories" },
    { name: "Contact messages", path: "/contact-messages" },
  ];

  const logoutHandler = (e) => {
    gtx.setLoading(true);
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_AUTHENTICATION_URL}logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies["access-token"]}`,
          },
        }
      )
      .then((res) => {
        removeCookie("access-token");
        gtx.setLoggedIn(false);
        navigate("/login");
      }).catch((err) => {
        gtx.setLoggedIn(false);
        navigate("/login");
      });
  };
  return (
    <Fragment>
      <div className="container-fluid px-0">
        <div className="row w-100 h-100">
          <div className="col-3">
            <div className="d-flex flex-column side-menu h-100">
              <ul className="h-100">
                {menuItems.map((page, index) => (
                  <li key={index}>
                    <NavLink
                      to={page.path}
                      className={props.active === page.name ? "active" : ""}
                    >
                      {page.name}
                    </NavLink>
                  </li>
                ))}
                <li className="logout-button">
                  <button onClick={logoutHandler}>LOG OUT</button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-9">{props.children}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
