import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useCookies } from "react-cookie";
import { MdDelete } from "react-icons/md";
import DataLoader from "../components/DataLoader";
import DeleteModal from "../components/DeleteModal";
import GlobalContext from "../GlobalContext";
import ButtonLoader from "../components/ButtonLoader";
import { useForm } from "react-hook-form";

const HomeCarousel = () => {
  const gtx = useContext(GlobalContext);
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [cookies] = useCookies(["access-token"]);
  const [fetchData, setFetchData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [requestErrors, setRequestErrors] = useState("");
  const [deleteErrors, setDeleteErrors] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      info: "",
    },
  });

  // Get data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}carousel_images`)
      .then((res) => {
        setData(res.data.data);
        setLoadingData(false);
      })
      .catch((err) => {
        throw err;
      });
  }, [fetchData]);

  // Add data
  const submitHandler = (e) => {
    setButtonDisabled(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}carousel_images`,
      data: { carousel_image: e.image[0] },
      headers: {
        Authorization: `Bearer ${cookies["access-token"]}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        setButtonDisabled(false);
        setFetchData(!fetchData);
        setRequestErrors("");
        reset();
      })
      .catch(function (err) {
        setRequestErrors(err.message);
        setButtonDisabled(false);
        throw err;
      });
  };

  // Delete data
  const modalHandler = (link) => {
    setShowModal(true);
    setItemId(link);
  };

  const deleteHandler = () => {
    setShowModal(false);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}carousel_images/${itemId}`,
      data: {},
      headers: {
        Authorization: `Bearer ${gtx.cookies["access-token"]}`,
      },
    })
      .then(function (response) {
        setFetchData(!fetchData);
      })
      .catch(function (err) {
        setDeleteErrors(err);
        throw err;
      });
  };

  return (
    <Layout active="Home Carousel">
      <form className="pt-5" onSubmit={handleSubmit(submitHandler)}>
        <div className="form-wrapper">
          <p className="form-wrapper-title">Home carousel</p>
          <label htmlFor="addImage">Add Image</label>
          <input
            type="file"
            id="addImage"
            name="image"
            {...register("image", {
              required: {
                value: true,
                message: "empty",
              },
            })}
          />
          {errors.image?.message === "empty" && (
            <p className="error-message">This field is required</p>
          )}
          {requestErrors !== "" && (
            <p className="error-message">Something went wrong</p>
          )}
          <button type="submit" disabled={buttonDisabled}>
            {buttonDisabled ? <ButtonLoader /> : "Add image"}
          </button>
        </div>
      </form>
      <div className="row pt-2 pb-5">
        {loadingData ? (
          <div className="col-12 pt-5 d-flex justify-content-center">
            <DataLoader />
          </div>
        ) : (
          data.map((link, index) => (
            <div key={index} className="col-3 pt-4">
              <div className="ratio ratio-1x1">
                <img
                  src={process.env.REACT_APP_IMAGES_URL + link.image}
                  alt=""
                  className="cover"
                />
                <button
                  onClick={() => modalHandler(link.id)}
                  className="delete-button"
                >
                  <div className="ratio ratio-1x1">
                    <MdDelete style={{ color: "red" }} />
                  </div>
                </button>
              </div>
            </div>
          ))
        )}
        {deleteErrors !== "" && (
          <p className="error-message">Something went wrong</p>
        )}
      </div>
      <DeleteModal
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        onDelete={deleteHandler}
      />
    </Layout>
  );
};

export default HomeCarousel;
